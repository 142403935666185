import React, { useEffect } from "react";
import { Button } from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner";

// 페이지 단위에서 사용할 에러바운더리의 fallback
const ErrorPage = ({
  error,
  resetBoundary,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  resetBoundary: () => void;
}) => {
  useEffect(() => {
    // 에러 발생 시 메인 페이지로 이동
    location.href = "/";
  }, []);

  return (
    // <div id="error-page">
    //   <div className="number">{error?.response?.status as number}</div>
    //   <div className="text">
    //     <span>Oops!</span>
    //     <br />
    //     {error?.message ?? "에러가 발생했습니다."}
    //   </div>
    //   <Button
    //     variant="contained"
    //     onClick={resetBoundary}
    //     className="reset-button"
    //   >
    //     새로고침
    //   </Button>
    //   <Button
    //     variant="contained"
    //     onClick={() => (location.href = "/")}
    //     className="reset-button"
    //   >
    //     메인으로
    //   </Button>
    // </div>
    <LoadingSpinner />
  );
};

export default React.memo(ErrorPage);
