import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteCart, getCartList, insertCart, updateCart } from "api/cartApi";
import dayjs from "dayjs";
import { useBookListWithBookCdQuery } from "queries/bookQuery";
import { useShopListQuery } from "queries/shopQuery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ResBaseVo } from "types/api";
import { Book, CartItem, CartList } from "types/book";
import { Shop } from "types/shop";
import {
  makeBookPriceAfterDiscount,
  makeBookReserveAmount,
} from "utils/commonUtils";
import { useMyNavigation } from "./useMyNavigation";
import useUser from "./useUser";

export const useCartAsync = () => {
  const { pathname, search } = useLocation();
  const { user, isSignedIn } = useUser();
  const [cartList, setCartList] = useState<CartItem[]>([]);
  // 장바구니 페이지에서 필요한 입점몰/후원단체 데이터
  const { shopListData } = useShopListQuery();
  const [selectedItem, setSelectedItem] = useState<CartItem[]>([]);
  // 일단 장바구니를 가져오지만, 장바구니에는 책에 대한 정보가 productSeq 뿐임.
  const { data: cartListData, refetch: refetchCartList } = useQuery<
    ResBaseVo<{ bkmrList: CartList[] }>
  >(["CART", user.userId], getCartList, {
    staleTime: 0,
    cacheTime: 0,
    enabled: pathname.includes("cart"),
  });

  const { mutate: insertCartMutate } = useMutation(insertCart, {
    onSuccess: refetchCartList,
    onError: () => {
      if (confirm("로그인이 필요합니다. 로그인하시겠습니까?")) {
        location.href = `/sign-in?callback=${pathname}${search}`;
      } else {
        location.reload();
      }
    },
  });

  const { mutate: deleteCartMutate } = useMutation(deleteCart, {
    onSuccess: refetchCartList,
  });

  const { mutate: updateCartMutate } = useMutation(updateCart);
  const [searchParams] = useSearchParams();
  const shopSeq = useMemo(
    () =>
      searchParams.get("shopSeq") ?? `${process.env.REACT_APP_MODOO_METAX_NO}`,
    [searchParams],
  );

  const productSeqString = useMemo(() => {
    if (!cartListData) return "";
    const bkmrList = cartListData.data.bkmrList;
    let productSeqString = "";
    for (let i = 0; i < bkmrList.length; i++) {
      if (i == 0) productSeqString += `${bkmrList[i].productSeq}`;
      else productSeqString += `;${bkmrList[i].productSeq}`;
    }

    return productSeqString;
  }, [cartListData]);

  const shopSeqString = useMemo(() => {
    if (!cartListData) return "";
    const bkmrList = cartListData.data.bkmrList;
    let shopSeqString = "";
    for (let i = 0; i < bkmrList.length; i++) {
      if (i == 0) shopSeqString += `${bkmrList[i].shopSeq}`;
      else shopSeqString += `;${bkmrList[i].shopSeq}`;
    }

    return shopSeqString;
  }, [cartListData]);

  const { bookList } = useBookListWithBookCdQuery(
    productSeqString,
    undefined,
    shopSeqString,
  );

  const { navigate } = useMyNavigation();

  const { totalAmount, totalReserveAmount, shippingFee } = useMemo(() => {
    const totalAmount = selectedItem.reduce(
      (amount, item) =>
        amount + item.qty * makeBookPriceAfterDiscount(item.item),
      0,
    );
    const totalReserveAmount = selectedItem.reduce(
      (amount, item) => amount + item.qty * makeBookReserveAmount(item.item),
      0,
    );
    // 임시로 배송비 0원 처리, 추후 주석 해제 필요
    // const shippingFee = totalAmount == 0 || totalAmount >= 30000 ? 0 : 3000;
    const shippingFee = 0;

    return {
      totalAmount,
      totalReserveAmount,
      shippingFee,
    };
  }, [selectedItem]);

  const shopList = useMemo(() => {
    if (shopListData.length === 0) return [];
    if (!cartListData) return [];

    const modooBook = cartListData.data.bkmrList.find(
      (datum) => datum.shopSeq === `${process.env.REACT_APP_MODOO_METAX_NO}`,
    );

    let rtnShopList: Shop[] = [];

    if (modooBook) {
      rtnShopList = [
        {
          shopSeq: `${process.env.REACT_APP_MODOO_METAX_NO}`,
          shopTypCd: "M",
          bannerTitle: "책광장모두",
          address: "",
          contact: "",
          introContent: "",
          introImg: "",
          hashTag: [] as string[],
          cheersCnt: 0,
          bannerSub: "",
        },
        ...cartListData.data.bkmrList
          .reduce(
            (prev, item) =>
              prev.includes(item.shopSeq) ? prev : [...prev, item.shopSeq],
            [] as string[],
          )
          // 샵 데이터를 찾는데, 없으면 undefined를 리턴해서 shop과 undefined가 섞인 배열이 된다.
          .map((shopSeq) => {
            const shop = shopListData.find(
              (shopData) => shopData.shopSeq == shopSeq,
            );
            if (shop) {
              return shop;
            } else {
              return;
            }
          })
          // 여기서 !!shop을 통해 undefined를 걸러내고,
          // 책광장모두는 0번 인덱스에 있으니까 걸러낸다.
          .filter(
            (shop) =>
              !!shop &&
              shop.shopSeq !== `${process.env.REACT_APP_MODOO_METAX_NO}`,
          ),
      ] as Shop[];
    } else {
      rtnShopList = [
        ...cartListData.data.bkmrList
          .reduce(
            (prev, item) =>
              prev.includes(item.shopSeq) ? prev : [...prev, item.shopSeq],
            [] as string[],
          )
          // 샵 데이터를 찾는데, 없으면 undefined를 리턴해서 shop과 undefined가 섞인 배열이 된다.
          .map((shopSeq) => {
            const shop = shopListData.find(
              (shopData) => shopData.shopSeq == shopSeq,
            );
            if (shop) {
              return shop;
            } else {
              return;
            }
          })
          // 여기서 !!shop을 통해 undefined를 걸러내고,
          // 책광장모두는 0번 인덱스에 있으니까 걸러낸다.
          .filter(
            (shop) =>
              !!shop &&
              shop.shopSeq !== `${process.env.REACT_APP_MODOO_METAX_NO}`,
          ),
      ] as Shop[];
    }

    // 직영몰, 입점몰, 후원단체를 구분짓기 위해 필요한 몰 정보를 가져온다.
    return rtnShopList;
  }, [cartListData, shopListData]);

  // cartList를 기본 데이터만 set 이후 bookCd 기반 데이터 set용
  // 아래 로직이 없을 시 장바구니 페이지 최초 접속 시에만 정상 작동함
  const handleCarListDataSet = useCallback(() => {
    if (bookList.length > 0) {
      setCartList((prev) => [
        ...prev
          .map((item) => {
            const book = bookList.find((b) => b.bookCd == item.item.bookCd);
            if (book)
              return {
                ...item,
                item: book,
              };
            return { ...item };
          })
          // 만약 데이터 이슈로 책을 못 받아왔을 경우, 필터링한다.
          .filter((item) => item.item.bookTitle.length > 0),
      ]);
      setSelectedItem((prev) => [
        ...prev
          .map((item) => {
            const book = bookList.find((b) => b.bookCd == item.item.bookCd);
            if (book)
              return {
                ...item,
                item: book,
              };
            return { ...item };
          })
          // 만약 데이터 이슈로 책을 못 받아왔을 경우, 필터링한다.
          .filter((item) => item.item.bookTitle.length > 0),
      ]);
    }
  }, [bookList]);

  // 장바구니 데이터가 돌아왔을 때의 처리
  useEffect(() => {
    if (cartListData) {
      // cart R
      // 일단 bookCd만 들어있는 빈 책을 넣고,
      // 책은 따로 요청을 보낸 다음 bookCd를 기반으로 채워넣어준다.
      const newCartList: CartItem[] = [
        ...cartListData.data.bkmrList.map((bookmark) => ({
          qty: bookmark.qty,
          cartId: bookmark.cartSeq,
          shopSeq: bookmark.shopSeq,
          item: {
            bookCd: bookmark.productSeq,
            bookTitle: "",
            openDate: dayjs().format("YYYYMMDD"),
            author: "",
            bookPrice: 0,
            bookMarkCnt: 0,
            pubName: "",
            isbn: "",
            ipgoDate: "",
            outGb: "A" as const,
            outGbnm: "정상",
            jgQty: 0,
            purchaseRate: 100,
            topic: "",
            isNewBook: false,
            onelineComment: "",
            discountRate: 10,
            rsvRate: 5,
            adultYn: "N",
            reviewCnt: 0,
            tags: [],
            reviewData: [],
            cardNews: [],
            bookPreviewImage: "",
            addSign: [],
            seriesTitle: [],
            bookSize: [],
            kcAuth: [],
          },
        })),
      ];
      setCartList(newCartList);
      setSelectedItem(newCartList);
      handleCarListDataSet();
    }
  }, [cartListData, shopListData, handleCarListDataSet]);

  // 책 정보가 돌아왔을 때 - bookCd 기반으로 맞춰서 넣어줌.
  useEffect(() => {
    if (bookList.length > 0) {
      setCartList((prev) => [
        ...prev
          .map((item) => {
            const book = bookList.find((b) => b.bookCd == item.item.bookCd);
            if (book)
              return {
                ...item,
                item: book,
              };
            return { ...item };
          })
          // 만약 데이터 이슈로 책을 못 받아왔을 경우, 필터링한다.
          .filter((item) => item.item.bookTitle.length > 0),
      ]);
      setSelectedItem((prev) => [
        ...prev
          .map((item) => {
            const book = bookList.find((b) => b.bookCd == item.item.bookCd);
            if (book)
              return {
                ...item,
                item: book,
              };
            return { ...item };
          })
          // 만약 데이터 이슈로 책을 못 받아왔을 경우, 필터링한다.
          .filter((item) => item.item.bookTitle.length > 0),
      ]);
    }
  }, [bookList]);

  const handleSelectItemClick = useCallback(
    (newCartItem: CartItem) => () => {
      setSelectedItem((prev) => {
        if (prev.find((item) => item.cartId == newCartItem.cartId)) {
          // 있으면
          return [...prev.filter((item) => item.cartId != newCartItem.cartId)];
        } else {
          // 없으면
          return [...prev, newCartItem];
        }
      });
    },
    [],
  );

  const handleSelectAllButtonClick = useCallback(() => {
    setSelectedItem((prev) =>
      prev.length == cartList.length && cartList.length > 0
        ? []
        : [...cartList],
    );
  }, [cartList]);

  const handleInsertItem = useCallback(
    ({
      newBookList,
      qty = 1,
      confirmText = "장바구니에 담겼습니다. 장바구니로 이동하시겠습니까?",
    }: {
      newBookList: Book[];
      qty?: number;
      confirmText?: string;
    }) => {
      if (!isSignedIn) {
        if (confirm("로그인이 필요합니다. 로그인하시겠습니까?")) {
          navigate(`/sign-in?callback=${pathname}${search}`);
          return;
        }
      }
      insertCartMutate(
        newBookList.map((book) => ({ productSeq: book.bookCd, qty, shopSeq })),
        {
          onSuccess: () => {
            if (confirm(confirmText)) {
              navigate("/cart-view");
            }
          },
        },
      );
    },
    [insertCartMutate, isSignedIn, navigate, pathname, search, shopSeq],
  );

  const handleInsertCartButtonClick = useCallback(
    (newBookList: Book[], qty?: number) => () => {
      // 로그인을 하지 않은 경우
      if (!isSignedIn) {
        if (confirm("로그인이 필요합니다. 로그인하시겠습니까?")) {
          navigate(`/sign-in?callback=${location.pathname}${location.search}`);
        }
        return;
      }

      // 제품을 선택하지 않은 경우
      if (newBookList.length == 0) {
        alert("주문하실 도서를 선택해주세요.");
        return;
      }

      /**
       * 구매가능도서 목록
       * 1. 도서 상태가 A인 도서
       * 2. 도서 상태가 B/C/D이면 재고가 있고, 주문부수가 재고부수 이하여야함
       * 위 두 조건인 경우에만 주문 가능
       * A: 정상, B: 임시품절, C: 품절, D: 절판
       */
      const availableBookList = newBookList.filter(
        (book) =>
          book.outGb == "A" ||
          ((book.outGb == "B" || book.outGb == "C" || book.outGb == "D") &&
            book.jgQty > 0 &&
            book.jgQty >= (qty ?? 0)),
      );

      // 살 수 있는 책이 없는 경우
      if (availableBookList.length == 0) {
        alert("품절 혹은 절판으로 구매가 불가능합니다.");
        return;
      }

      // 성인인 경우 추가 로직이 필요없다.
      if (user.adultYn == "Y") {
        handleInsertItem({ newBookList: availableBookList, qty });
        return;
      }

      // 미성년의 경우 - 성인도서를 제외한다.
      const filteredBookList = availableBookList.filter(
        (book) => (book?.adultYn ?? "") != "Y",
      );

      // 전부 성인도서였던 경우
      if (filteredBookList.length == 0) {
        alert("성인인증이 필요합니다.");
        return;
      }

      let confirmText = "장바구니에 담겼습니다. 장바구니로 이동하시겠습니까?";

      // 안 담기는 도서가 생길 시 confirm text에 변화를 줌
      if (filteredBookList.length != availableBookList.length) {
        confirmText = `성인 도서 ${
          availableBookList.length - filteredBookList.length
        }권을 제외한 ${
          filteredBookList.length
        }권을 장바구니에 담겼습니다. 장바구니로 이동하시겠습니까?`;
      }
      // 미성년자가 살 수 없는 도서를 제외하고 insert
      handleInsertItem({
        newBookList: filteredBookList,
        qty,
        confirmText,
      });
    },
    [handleInsertItem, isSignedIn, navigate, user.adultYn],
  );

  const handleUpdateItem = useCallback(
    (cartId: string, qty: number) => () => {
      // cart U
      // if (qty == 0) {
      //   alert("최소 구매 가능 수량은 1개입니다.");
      //   return;
      // }
      updateCartMutate(
        {
          cartSeq: cartList.find((item) => item.cartId == cartId)?.cartId ?? "",
          qty,
        },
        {
          onSuccess: () => {
            setCartList((prev) => {
              const idx = prev.findIndex(
                (bookmark) => bookmark.cartId == cartId,
              );
              if (idx < 0) return prev;
              prev[idx].qty = qty;
              return [...prev];
            });
            setSelectedItem((prev) => {
              const index = prev.findIndex((book) => book.cartId == cartId);
              if (index < 0) return prev;
              prev[index].qty = qty;
              return [...prev];
            });
          },
        },
      );
    },
    [cartList, updateCartMutate],
  );

  const handleDeleteItem = useCallback(
    (deleteItemList: Book[]) => () => {
      // cart D
      if (deleteItemList.length == 0) {
        alert("삭제할 상품을 선택해주세요.");
        return;
      }
      if (!confirm("장바구니에서 삭제하시겠습니까?")) return;
      deleteCartMutate(
        cartList
          .filter(
            (item) =>
              !!deleteItemList.find((del) => del.bookCd == item.item.bookCd),
          )
          .map((item) => ({
            cartSeq: item.cartId,
          })),
        {
          onSuccess: () => setSelectedItem([]),
        },
      );
    },
    [cartList, deleteCartMutate],
  );

  return {
    cartList,
    setCartList,
    totalAmount,
    totalReserveAmount,
    selectedItem,
    shippingFee,
    setSelectedItem,
    handleInsertItem,
    handleDeleteItem,
    handleUpdateItem,
    handleInsertCartButtonClick,
    handleSelectItemClick,
    handleSelectAllButtonClick,
    shopList,
  };
};
